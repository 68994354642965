/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
import iteesPage from './itees-page'
import iteesParticipant from './itees-participant'
import iteesExperts from './itees-experts'
import iteesStaff from './itees-staff'

export default{
	1: iteesPage,
	2: iteesParticipant, // 1 or 2 là group id
	3: iteesExperts, 
	12: iteesStaff,  // group id là 1, 2 là rule là Staff => 12
}


// Array of sections
//export default [...iteesPage]
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
