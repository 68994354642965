export default [
    {
        title: 'Dashboard',
        route: 'dashboard-expert',
        icon: 'svgIco',
        svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.0001 15.75H19.5001V19.5H18.0001V15.75ZM15.0001 12H16.5001V19.5H15.0001V12ZM8.25006 19.5C7.25586 19.4988 6.30273 19.1033 5.59973 18.4003C4.89672 17.6973 4.50125 16.7442 4.50006 15.75H6.00006C6.00006 16.195 6.13202 16.63 6.37925 17C6.62649 17.37 6.97789 17.6584 7.38902 17.8287C7.80016 17.999 8.25256 18.0436 8.68901 17.9568C9.12547 17.87 9.52638 17.6557 9.84105 17.341C10.1557 17.0263 10.37 16.6254 10.4568 16.189C10.5436 15.7525 10.4991 15.3001 10.3288 14.889C10.1585 14.4778 9.8701 14.1264 9.50009 13.8792C9.13008 13.632 8.69507 13.5 8.25006 13.5V12C9.24462 12 10.1985 12.3951 10.9017 13.0983C11.605 13.8016 12.0001 14.7554 12.0001 15.75C12.0001 16.7446 11.605 17.6984 10.9017 18.4017C10.1985 19.1049 9.24462 19.5 8.25006 19.5Z" fill="black"/> <path d="M21.0001 1.5H3.00006C2.60236 1.5004 2.22106 1.65856 1.93984 1.93978C1.65862 2.221 1.50046 2.6023 1.50006 3V21C1.50046 21.3977 1.65862 21.779 1.93984 22.0602C2.22106 22.3414 2.60236 22.4996 3.00006 22.5H21.0001C21.3977 22.4994 21.7789 22.3412 22.0601 22.06C22.3412 21.7788 22.4995 21.3976 22.5001 21V3C22.4997 2.6023 22.3415 2.221 22.0603 1.93978C21.7791 1.65856 21.3978 1.5004 21.0001 1.5ZM21.0001 8.25H10.5001V3H21.0001V8.25ZM9.00006 3V8.25H3.00006V3H9.00006ZM3.00006 21V9.75H21.0001L21.0016 21H3.00006Z" fill="black"/> </svg>'
    },
    {
        title: 'Our Portfolio',
        route: 'expert-our-portfolio',
        icon: 'svgIco',
        svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21.9322 3.02269L15.9322 1.52269C15.7789 1.48467 15.6176 1.49568 15.4709 1.55419L8.19594 4.46419L2.43219 3.02269C2.3216 2.99499 2.20615 2.99286 2.09462 3.01647C1.98309 3.04008 1.87841 3.0888 1.78853 3.15894C1.69865 3.22908 1.62595 3.31878 1.57594 3.42123C1.52593 3.52368 1.49994 3.63619 1.49994 3.75019V20.2502C1.49994 20.4174 1.55582 20.5798 1.65868 20.7116C1.76155 20.8434 1.9055 20.9371 2.06769 20.9777L8.06769 22.4777C8.12732 22.4925 8.18851 22.5 8.24994 22.5002C8.34554 22.5002 8.44026 22.4819 8.52894 22.4462L15.8039 19.5362L21.5684 20.9777C21.679 21.0053 21.7943 21.0073 21.9058 20.9836C22.0172 20.9599 22.1218 20.9112 22.2116 20.8411C22.3014 20.771 22.374 20.6813 22.424 20.5789C22.4739 20.4765 22.4999 20.3641 22.4999 20.2502V3.75019C22.4999 3.58299 22.4441 3.42059 22.3412 3.28879C22.2383 3.15698 22.0944 3.06332 21.9322 3.02269ZM20.9999 8.25019H16.4999V3.21019L20.9999 4.33519V8.25019ZM7.49994 14.2502H2.99994V9.75019H7.49994V14.2502ZM8.99994 8.25019V5.75794L14.9999 3.35794V8.25019H8.99994ZM14.9999 9.75019V14.2502H8.99994V9.75019H14.9999ZM8.99994 15.7502H14.9999V18.2424L8.99994 20.6424V15.7502ZM16.4999 9.75019H20.9999V14.2502H16.4999V9.75019ZM7.49994 5.83519V8.25019H2.99994V4.71019L7.49994 5.83519ZM2.99994 15.7502H7.49994V20.7902L2.99994 19.6652V15.7502ZM16.4999 18.1644V15.7502H20.9999V19.2902L16.4999 18.1644Z" fill="black"/> </svg>'
    },
]
