<template>
  
    
    <li
      v-if="canViewVerticalNavMenuLink(item)"
      class="nav-item"
      :class="{
        'active': isActive,
        'disabled': item.disabled
      }"
    >
      <!-- {{ userData.role }} -->
     <!--  <template v-if="userData.group_id == 1">
          <template v-for="n in userData.role">            
              <template v-if="n.role_id == 1">
                  <b-link
                      v-if="item.route === 'logout'" 
                      v-bind="linkProps"
                      class="d-flex align-items-center"
                      @click="logout" >

                      <span v-if="item.icon === 'svgIco'" class="svgIcon-menu">          
                          <span v-html="item.svg"></span>
                      </span>
                      <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
                      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
                      <b-badge
                        v-if="item.tag"
                        pill
                        :variant="item.tagVariant || 'primary'"
                        class="mr-1 ml-auto"
                      >
                        {{ item.tag }}
                      </b-badge>
                  </b-link>
                  <b-link
                      v-else 
                      v-bind="linkProps"
                      class="d-flex align-items-center" >

                      <span v-if="item.icon === 'svgIco'" class="svgIcon-menu">          
                          <span v-html="item.svg"></span>
                      </span>
                      <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
                      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
                      <b-badge
                        v-if="item.tag"
                        pill
                        :variant="item.tagVariant || 'primary'"
                        class="mr-1 ml-auto"
                      >
                        {{ item.tag }}
                      </b-badge>
                  </b-link>
              </template>            
          </template>
      </template> -->
      
        <b-link
            v-if="item.route === 'logout'" 
            v-bind="linkProps"
            class="d-flex align-items-center"
            @click="logout" >

            <span v-if="item.icon === 'svgIco'" class="svgIcon-menu">          
                <span v-html="item.svg"></span>
            </span>
            <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
            <span class="menu-title text-truncate">{{ t(item.title) }}</span>
            <b-badge
              v-if="item.tag"
              pill
              :variant="item.tagVariant || 'primary'"
              class="mr-1 ml-auto"
            >
              {{ item.tag }}
            </b-badge>
        </b-link>
        <b-link
            v-else 
            v-bind="linkProps"
            class="d-flex align-items-center" >

            <span v-if="item.icon === 'svgIco'" class="svgIcon-menu">          
                <span v-html="item.svg"></span>
            </span>
            <feather-icon v-else :icon="item.icon || 'CircleIcon'" />
            <span class="menu-title text-truncate">{{ t(item.title) }}</span>
            <b-badge
              v-if="item.tag"
              pill
              :variant="item.tagVariant || 'primary'"
              class="mr-1 ml-auto"
            >
              {{ item.tag }}
            </b-badge>
        </b-link>
    </li>
    
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {

      logout() {

        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      },
  }

}
</script>
