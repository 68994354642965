<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0 iiii">
            {{ userData.full_name || userData.username }}
          </p>
          <!-- <span class="user-status">{{ userData.role }}</span> -->
          <span class="user-status"> {{ userData.role && userData.role[0] && userData.role.role_name ? userData.role.role_name : null }} </span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.full_name"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>
      <template v-if="userData.group == 1">
          <b-dropdown-item
            link-class="d-flex align-items-center justify-content-center"
            @click="myProfile" >
              <feather-icon
                size="16"
                icon="UserIcon"
                class="mr-50"
              />
              <span>My profile</span>
          </b-dropdown-item>
      </template>   
      <template v-if="userData.group == 3">
          <b-dropdown-item
            link-class="d-flex align-items-center justify-content-center"
            @click="expertProfile" >
              <feather-icon
                size="16"
                icon="UserIcon"
                class="mr-50"
              />
              <span>My profile</span>
          </b-dropdown-item>
      </template>
       <b-dropdown-item
          link-class="d-flex align-items-center justify-content-center"
          @click="logout" >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Logout</span>
        </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        // this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
    },
    myProfile(){
        this.$router.push({ name: 'admin_myprofile' })
    },
    expertProfile(){
        this.$router.push({ name: 'expert-profile' })
    }
  },
}
</script>
